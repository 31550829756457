.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: white;
}

.profile-section {
  margin: 2rem 0;
  max-width: 600px;
}

.profile-image {
  border-radius: 50%;
  border: 3px solid #61dafb;
  margin-bottom: 1rem;
}

.profile-bio {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  padding: 1rem;
}

.content-item {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.content-item:hover {
  transform: translateY(-5px);
}

.content-item h3 {
  color: #61dafb;
  margin-bottom: 1rem;
}

.cta-button {
  background-color: #61dafb;
  color: #282c34;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.cta-button:hover {
  background-color: #4fa8d3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow-y: auto;
  padding: 1rem;
}

.modal-content {
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
  margin: 1rem auto;
}

.order-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dots::after {
  content: '';
  animation: dotAnimation 2s infinite;
}

@keyframes dotAnimation {
  0% { content: '.'; }
  25% { content: '..'; }
  75% { content: '...'; }
  100% { content: '.'; }
}

.modal-content img {
  border-radius: 8px;
  border: 1px solid #495057;
}

.form-select option {
  background-color: #212529;
  color: white;
}

@media (max-height: 800px) {
  .modal-overlay {
    align-items: flex-start;
  }
  
  .modal-content {
    margin: 1rem auto;
  }
}

.carousel-item img {
  max-height: 70vh;
  object-fit: contain;
  border-radius: 8px;
}

.carousel-control-prev,
.carousel-control-next {
  background: none;
  border: none;
  cursor: pointer;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 0.8;
}